import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();

    useEffect(() => {
        const userToken = localStorage.getItem("user_token");
        if (userToken) {
            setUser(userToken);
            return;
        }
    }, []);

    const signin = async (email, password) => {
        const response = await axios.post('https://newapi.vidamistica.com/auth', {'email': email});
        if(Object.keys(response.data).length !== 0) {
            localStorage.removeItem('user_products');
            const token = Math.random().toString(36).substring(2);
            response.data.products.map(product => {
                if(product.product_id === 5){
                    localStorage.setItem("week_orientation", product.orientation_url);
                }
                if(product.product_id === 4) {
                    if(product.generated === true) {
                        const current_date = new Date(product.generation_date);
                        localStorage.setItem("soulmate_deadline", new Date(current_date.getTime() + 720*60000));
                        localStorage.setItem("soulmate_image", product.image_path);
                    }
                }
                if(product.product_id === 3) {
                    if(product.generated === true) {
                        const current_date = new Date(product.generation_date);
                        localStorage.setItem("tarot_deadline", new Date(current_date.getTime() + 720*60000));
                        localStorage.setItem("tarot_path", product.product_name);
                    }
                }
                if(product.product_id === 2) {
                    if(product.generated === true) {
                        localStorage.setItem("numerology_path", product.product_name);
                    }
                }

                if(product.product_id === 1) {
                    if(product.generated === true) {
                        localStorage.setItem("astrology_path", product.product_name);
                    }
                }
            });
            localStorage.setItem("subscription_week", response.data.user_data.subscription_week);
            localStorage.setItem("user_products", JSON.stringify(response.data.products));
            localStorage.setItem("user_token", JSON.stringify({ email, token }));
            setUser({ email });
            return true;
        }
        return false;
    }

    return (
        <AuthContext.Provider value={{ user, signed: !!user, signin }}>{children}</AuthContext.Provider>
    );
};
