import styled from "styled-components";
import { mobile480 } from "../responsive";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import  useAuth  from "../hooks/useAuth"
import { useEffect } from "react";

const Container = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    width: 100vw;
    justify-content: center;
`;

const Wrapper = styled.div`
    width: 40%;
    height: 100%;
    padding: 15px;
    margin: 40px 0px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    border-radius: 8px;

    ${mobile480({ width: "75%", fontSize: "18px", })}
`;

const Top = styled.div`

`;

const Logo = styled.div`

`;

const Desc = styled.p`
    margin: 10px 0px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h2`
    text-align: center;
    margin: 5px 0px 10px 0px;

    ${mobile480({ fontSize: "18px", })}
`;

const Label = styled.label`
    margin-bottom: 5px;
`;

const Input = styled.input`
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d3d3d3;
`;

const Tip = styled.p`
    font-size: 14px;
    margin: 5px 0px;
`;

const Button = styled.button`
    padding: 6px;
    margin: 20px 0px 10px 0px;
    font-size: 16px;
    border-radius: 15px;
    outline: none;
    border: none;
    font-weight: bold;
    background-color: rgb(255, 105, 180);
    border: 1px solid #ffffff;
    color: #ffffff;
`;

const Alert = styled.p`

`;

const Footer = styled.div`
    margin-top: 20px;
`;

const Info = styled.p`
    font-size: 14px;
    text-align: center;
`;

function LoginService() {
    const [signed, setSigned] = useState("");
    const { signin } = useAuth();
    const navigate = useNavigate();
    
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    
    useEffect(() => {
        if (localStorage.getItem("user_token")) {
            setSigned(true);
        }
        return;
    }, []);
    if (signed) {
        return <Navigate to="/home" />;
    }
    const handleLogin = (event) => {
        event.preventDefault();
        if (!email) {
            setError("Preencha com seu e-mail");
            return;
        }

        const res = signin(email);
        res.then((response) => { 
            if (response === false) {
                setError("Usuário não encontrado!");
                return;
            }
            navigate("/home");
        });
    }

    return (
        <Container>
            <Wrapper>
                <Top>
                    <Desc>
                        Parabéns, deu tudo certo! Você está a um passo de acessar todo o material…
                    </Desc>
                    <Desc>
                        Faça login na área de membros, onde todos os seus produtos e bônus estão disponíveis.
                    </Desc>
                </Top>
                <Form>
                    <Title>Entre na sua área de membro</Title>
                    <Label>Insira seu endereço de e-mail:</Label>
                    <Input placeholder="email@email.com" value={email} onChange={(e) => [setEmail(e.target.value), setError("")]}/>
                    <Tip>* Digite o mesmo e-mail utilizado na hora da compra.</Tip> 
                    <Alert>{error}</Alert>
                    <Button onClick={handleLogin}>Acessar Área de Membros</Button>
                </Form>
                <Footer>
                    <Info>
                        Precisa de ajuda? Fale com a gente em suporte@vidamistica.com se você estiver tendo problemas para acessar a área de membros.
                    </Info>
                </Footer>
            </Wrapper>
        </Container>
    )   
}

export default LoginService
