import React, { useState } from 'react';
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import './Popup.css';
import axios from 'axios';
import {ToggleGroup} from '../pages/Home'

function PopupForm(props) {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    var userData = JSON.parse(localStorage.getItem('user_token'));
    var inputs = "";
    switch(props.product) {
        case "soulmate":
            inputs = <>
                <label class="input-label">Nome completo: </label>
                <input type="text" class="form-input" name="fullname" required></input>
                <label class="input-label">E-mail: </label>
                <input type="text" class="form-input" name="fullname" value={userData.email} readOnly></input>
                <label class="input-label">Gênero de nascimento: </label>
                <select name="gender" required class="form-input">
                    <option style={{display: "none"}}>Selecione seu gênero...</option>
                    <option value="male">Masculino</option>
                    <option value="female">Feminino</option>
                </select>
                <label class="input-label">Gênero desejado: </label>
                <select name="desired-gender" required class="form-input">
                    <option style={{display: "none"}}>Selecione seu gênero desejado...</option>
                    <option value="male">Masculino</option>
                    <option value="female">Feminino</option>
                </select>
                <label class="input-label">Data de nascimento: </label>
                <input type="date" name="dob" class="form-input" pattern="\d{2}-\d{2}-\d{4}" required></input>
                <button type="submit" id="submit-button" class="form-button">Enviar</button>
            </>;
        break;

        case "numerology":
            inputs = <>
                <label class="input-label">Nome completo: </label>
                <input type="text" class="form-input" name="fullname" required></input>
                <label class="input-label">E-mail: </label>
                <input type="text" class="form-input" name="fullname" value={userData.email} readOnly></input>
                <label class="input-label">Data de nascimento: </label>
                <input type="date" name="dob" class="form-input" pattern="\d{2}-\d{2}-\d{4}" required></input>
                <label class="input-label">Gênero de nascimento: </label>
                <select name="gender" required class="form-input">
                    <option style={{display: "none"}}>Selecione seu gênero...</option>
                    <option value="male">Masculino</option>
                    <option value="female">Feminino</option>
                </select>
                <button type="submit" id="submit-button" class="form-button">Enviar</button>
            </>;
        break;

        case "novodestino":
            inputs = <>
                <label class="input-label">Nome completo: </label>
                <input type="text" class="form-input" name="fullname" required></input>
                <label class="input-label">E-mail: </label>
                <input type="text" class="form-input" name="fullname" value={userData.email} readOnly></input>
                <label class="input-label">Data de nascimento: </label>
                <input type="date" name="dob" class="form-input" pattern="\d{2}-\d{2}-\d{4}" required></input>
                <label class="input-label">Gênero de nascimento: </label>
                <select name="gender" required class="form-input">
                    <option style={{display: "none"}}>Selecione seu gênero...</option>
                    <option value="male">Masculino</option>
                    <option value="female">Feminino</option>
                </select>
                <button type="submit" id="submit-button" class="form-button">Enviar</button>
            </>;
        break;

        case "tarot":
            inputs = <>
                <label class="input-label">Nome completo: </label>
                <input type="text" class="form-input" name="fullname" required></input>
                <label class="input-label">E-mail: </label>
                <input type="text" class="form-input" name="fullname" value={userData.email} readOnly></input>
                <label class="input-label">Data de nascimento: </label>
                <input type="date" name="dob" class="form-input" pattern="\d{2}-\d{2}-\d{4}" required></input>
                <label class="input-label">Gênero de nascimento: </label>
                <select name="gender" required class="form-input">
                    <option style={{display: "none"}}>Selecione seu gênero...</option>
                    <option value="male">Masculino</option>
                    <option value="female">Feminino</option>
                </select>
                <button type="submit" id="submit-button" class="form-button">Enviar</button>
            </>;
        break;
    } 
    const handleSubmit = event => {
        event.preventDefault();
        document.getElementById("submit-button").innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
        document.getElementById("submit-button").disabled = true;
        if (props.product === "soulmate") {
            axios.post(
                'https://newapi.vidamistica.com/product/soulmate',
                {
                    'client_email': event.target[1].value,
                    'date_of_birth': event.target[4].value,
                    'gender': event.target[2].value,
                    'desired_gender': event.target[3].value
                }
            ).then((response) => {
                var currentdate = new Date(); 
                localStorage.setItem("soulmate_deadline", new Date(currentdate.getTime() + 720*60000));
                props.setGenerated(true);
                props.setActive();
                props.setActive('Desenho da Alma Gêmea');
                setOpen(false);
            });
        }
        if (props.product === "numerology") {
            axios.post(
                'https://newapi.vidamistica.com/product/numerology',
                {
                    "fullname": event.target[0].value,
                    "client_email": event.target[1].value,
                    "date_of_birth": event.target[2].value,
                    "gender": event.target[3].value
                }
            ).then((response) => {
                localStorage.setItem('numerology_path', response.data.product_path);
                props.setGenerated(true);
                props.setActive();
                props.setActive('Numerologia');
                setOpen(false);
            });
        }
        if (props.product === "novodestino") {
            axios.post(
                'https://newapi.vidamistica.com/product/astrology',
                {
                    "fullname": event.target[0].value,
                    "client_email": event.target[1].value,
                    "date_of_birth": event.target[2].value,
                    "gender": event.target[3].value
                }
            ).then((response) => {
                localStorage.setItem('astrology_path', response.data.product_path);
                props.setGenerated(true);
                props.setActive();
                props.setActive('Novo Destino');
                setOpen(false);
            });
        }
        if (props.product === "tarot") {
            axios.post(
                'https://newapi.vidamistica.com/product/tarot',
                {
                    "fullname": event.target[0].value,
                    "client_email": event.target[1].value,
                    "date_of_birth": event.target[2].value,
                    "gender": event.target[3].value
                }
            ).then((response) => {
                props.setGenerated(true);
                props.setActive();
                props.setActive('Tarot');
                setOpen(false);
            });
        }
    };
    return(
        <>
            <button type="button" className="button" onClick={() => setOpen(o => !o)}>
                Preencher informações
            </button>
            <Popup open={open} onClose={closeModal}>
                <form id="popup-form" onSubmit={handleSubmit}>
                    <div className="headliner">
                        <h2>Complete suas informações</h2>
                    </div>
                    {inputs}
                </form>
            </Popup>
        </>
    );
}

export default PopupForm;